import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, message, Modal, Form, Input, Popconfirm } from 'antd';
import { url } from '../index';

const MedicineRoute = () => {
    const [routes, setRoutes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [editingRoute, setEditingRoute] = useState(null); // For editing routes

    useEffect(() => {
        fetchRoutes();
    }, []);

    // Function to fetch all routes
    const fetchRoutes = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${url}/api/medicineRoute`);
            // Make sure the data is in array format and update state
            setRoutes(response.data.data || []); // response.data.data should contain the array of routes
        } catch (error) {
            message.error('Failed to fetch routes');
        } finally {
            setLoading(false);
        }
    };

    // Function to show modal for adding new route
    const showAddRouteModal = () => {
        setEditingRoute(null); // Reset editing state
        setIsModalVisible(true);
    };

    // Function to handle form submission for add/edit
    const handleAddOrUpdateRoute = async (values) => {
        try {
            if (editingRoute) {
                // Editing existing route
                await axios.put(`${url}/api/medicineRoute/${editingRoute.id}`, values); // Update route
                message.success('Route updated successfully');
            } else {
                // Adding new route
                const response = await axios.post(`${url}/api/medicineRoute`, values); // Add new route
                message.success('Route added successfully');
                if (response.status === 201) {
                    setRoutes([...routes, response.data.data]); // Add new route to state
                }
            }
            setIsModalVisible(false);
            form.resetFields(); // Reset form after submission
            fetchRoutes(); // Refresh the route list
        } catch (error) {
            message.error('Failed to save route');
        }
    };

    // Handle cancel button in modal
    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields(); // Reset form on cancel
    };

    // Function to handle editing
    const handleEdit = (record) => {
        setEditingRoute(record); // Set the record to edit
        form.setFieldsValue(record); // Pre-fill form with existing values
        setIsModalVisible(true); // Show modal
    };

    // Function to handle deleting a route
    const handleDelete = async (id) => {
        try {
            await axios.delete(`${url}/api/medicineRoute/${id}`); // Delete route
            message.success('Route deleted successfully');
            fetchRoutes(); // Refresh the route list
        } catch (error) {
            message.error('Failed to delete route');
        }
    };

    const columns = [
        { title: 'ID', dataIndex: 'id', key: 'id' },
        { title: 'Route Name', dataIndex: 'routeName', key: 'routeName' },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <span>
                    <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
                    <Popconfirm
                        title="Are you sure you want to delete this route?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" danger>Delete</Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    return (
        <div>
            <h1>Medicine Routes</h1>

            {/* Add Route Button */}
            <Button type="primary" onClick={showAddRouteModal} style={{ marginBottom: 16 }}>
                {editingRoute ? 'Edit Route' : 'Add Route'}
            </Button>

            {/* Table to display the medicine routes */}
            <Table columns={columns} dataSource={routes} rowKey="id" loading={loading} />

            {/* Modal for adding/editing a route */}
            <Modal
                title={editingRoute ? 'Edit Medicine Route' : 'Add Medicine Route'}
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} onFinish={handleAddOrUpdateRoute}>
                    <Form.Item
                        name="routeName"
                        label="Route Name"
                        rules={[{ required: true, message: 'Please enter the route name' }]}
                    >
                        <Input placeholder="Enter route name" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {editingRoute ? 'Update Route' : 'Add Route'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default MedicineRoute;
