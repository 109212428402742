import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, Select, Table, Pagination, message, Space, Tooltip } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { url } from '../index';
import './MedicineForm.css'; // Import CSS for styling


const MedicineForm = () => {
    const [types, setTypes] = useState([]);
    const [units, setUnits] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const [dosages, setDosages] = useState([]);
    const [medicines, setMedicines] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const [totalMedicines, setTotalMedicines] = useState(0);
    const [editingMedicine, setEditingMedicine] = useState(null);
    const [medicineOptions, setMedicineOptions] = useState([]);
    const [medicineRoutes, setRoutes] = useState([]); // Fixed variable name to 'medicineRoutes'

    const [form] = Form.useForm();
    const defaultStrength = '0';  
    const defaultUnitID = 1;

    useEffect(() => {
        fetchDropdownData();
        fetchMedicines(currentPage, pageSize);
    }, [currentPage]);

    const fetchDropdownData = async () => {
        try {
            const [typesRes, unitsRes, frequenciesRes, dosagesRes, medicineRoutesRes] = await Promise.all([ // Fixed variable name
                axios.get(`${url}/api/medicine-types`),
                axios.get(`${url}/api/units`),
                axios.get(`${url}/api/frequencies`),
                axios.get(`${url}/api/dosages`),
                axios.get(`${url}/api/medicineRoute`) // Fetch medicine routes
            ]);

            setTypes(typesRes.data.data || []);
            setUnits(unitsRes.data.data || []);
            setFrequencies(frequenciesRes.data.data || []);
            setDosages(dosagesRes.data.data || []);
            setRoutes(medicineRoutesRes.data.data || []); // Fixed variable name
        } catch (error) {
            message.error('Error fetching dropdown data');
        }
    };

    const fetchMedicines = async (page, pageSize) => {
        setLoading(true);
        try {
            const response = await axios.get(`${url}/api/medicines`, { params: { page, pageSize } });
            setMedicines(response.data.data || []);
            setTotalMedicines(response.data.total || 0);
        } catch (error) {
            message.error('Error fetching medicines');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (values) => {
        try {
            const compositionData = values.Composition.map(comp => ({
                MedicineName: comp.MedicineName,
                Strength: comp.Strength.match(/(\d+)/).input || defaultStrength,
                UnitID: comp.UnitID || defaultUnitID,
            }));
            

            const updatedValues = { ...values, Composition: compositionData, Strength: defaultStrength, UnitID: defaultUnitID,MedicineRoute: values.MedicineRoute };
            console.log(updatedValues);

            if (editingMedicine) {
                await axios.put(`${url}/api/medicines/${editingMedicine.ID}`, updatedValues);
                message.success('Medicine updated successfully');
            } else {
                await axios.post(`${url}/api/medicines`, updatedValues);
                message.success('Medicine added successfully');
            }

            form.resetFields();
            setEditingMedicine(null);
            fetchMedicines(currentPage, pageSize);
        } catch (error) {
            message.error('Error saving medicine');
        }
    };

    const handleEdit = (record) => {
        const formattedComposition = record.FullComposition?.split(' + ').map(comp => {
            const match = comp.match(/(\D+)(\d+.*)/);
            return match ? { MedicineName: match[1].trim(), Strength: match[2].split(' ')[0], UnitID: record.UnitID } : {};
        }) || [];

        setEditingMedicine(record);
        form.setFieldsValue({
            TypeID: record.TypeID,
            Name: record.Name,
            Strength: record.Strength,
            UnitID: record.UnitID,
            FrequencyID: record.FrequencyID,
            DosageID: record.DosageID,
            MedicineRoute: record.RouteID, // Ensure Medicine Route is set correctly
            Composition: formattedComposition,
            NumberOfDays: record.NumberOfDays
        });
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${url}/api/medicines/${id}`);
            message.success('Medicine deleted successfully');
            fetchMedicines(currentPage, pageSize);
        } catch (error) {
            message.error('Error deleting medicine');
        }
    };

    // const columns = [
    //     { title: 'S.No', key: 'sno',  render: (_, __, index) => (
    //         <span style={{ fontSize: '10px' }}>
    //           {(currentPage - 1) * pageSize + index + 1}
    //         </span>
    //       ) },
    //     { title: 'Type', dataIndex: 'TypeName', key: 'TypeName' },
    //     { title: 'Name', dataIndex: 'Name', key: 'Name' },
    //     { title: 'Composition + Strength + Unit', dataIndex: 'FullComposition', key: 'FullComposition' },
    //     {
    //         title: 'Dosage & Time',
    //         dataIndex: 'DosagePatternWithTime',
    //         key: 'DosagePatternWithTime',
    //         render: (text, record) => (
    //           <>
    //             <span style={{ fontSize: '10px' }}>{record.DosagePatternWithTime}</span><br />
    //             <span style={{ fontSize: '10px', className: 'hindi-text' }}>{record.DosagePatternWithTimeInHindi}</span>
    //           </>
    //         )
    //       }
    //       ,
    //     { title: 'Medicine Route', dataIndex: 'MedicineRouteName', key: 'MedicineRouteName' }, // Fixed Medicine Route column
    //     { title: 'Frequency', dataIndex: 'FrequencyName', key: 'FrequencyName' },
    //     { title: 'Number of Days', dataIndex: 'NumberOfDays', key: 'NumberOfDays' },
        
    //     {
    //         title: 'Actions',
    //         key: 'actions',
    //         render: (_, record) => (
    //             <Space>
    //                 <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
    //                 <Button type="link" danger onClick={() => handleDelete(record.ID)}>Delete</Button>
    //             </Space>
    //         )
    //     }
    // ];
 
    const columns = [
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>S.No</span>, 
        key: 'sno',
        render: (_, __, index) => (
          <span style={{ fontSize: '10px' }}>
            {(currentPage - 1) * pageSize + index + 1}
          </span>
        ),
      },
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>Type</span>, 
        dataIndex: 'TypeName', 
        key: 'TypeName',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>Name</span>, 
        dataIndex: 'Name', 
        key: 'Name',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>Composition + Strength + Unit</span>, 
        dataIndex: 'FullComposition', 
        key: 'FullComposition',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>Dosage & Time</span>, 
        dataIndex: 'DosagePatternWithTime', 
        key: 'DosagePatternWithTime',
        render: (text, record) => (
          <>
            <span style={{ fontSize: '10px' }}>{record.DosagePatternWithTime}</span><br />
            <span style={{ fontSize: '10px', fontFamily: 'serif' }}>{record.DosagePatternWithTimeInHindi}</span>
          </>
        )
      },
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>Medicine Route</span>, 
        dataIndex: 'MedicineRouteName', 
        key: 'MedicineRouteName',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>Frequency</span>, 
        dataIndex: 'FrequencyName', 
        key: 'FrequencyName',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>Number of Days</span>, 
        dataIndex: 'NumberOfDays', 
        key: 'NumberOfDays',
        render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>
      },
      {
        title: <span style={{ fontWeight: 'bold', color: '#4A90E2' }}>Actions</span>, 
        key: 'actions',
        render: (_, record) => (
          <Space>
            <Button type="link" onClick={() => handleEdit(record)} style={{ fontSize: '10px' }}>Edit</Button>
            <Button type="link" danger onClick={() => handleDelete(record.ID)} style={{ fontSize: '10px' }}>Delete</Button>
          </Space>
        ),
      }
    ];
    
    // Apply border styling in Table component
    const tableStyle = {
      border: '1px solid #d9d9d9',
      borderRadius: '8px',
      overflow: 'hidden'
    };
    
    <Table 
      columns={columns}
      bordered
      pagination={false}
      style={tableStyle}
    />
          
      

    const handlePageChange = (page) => setCurrentPage(page);

    return (
        <div className="medicine-form-container">
            <h1>Medicine Form</h1>
            <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ NumberOfDays: 5 }}>
                <Form.Item name="TypeID" label="Type of Medicine" rules={[{ required: true }]}>
                    <Select placeholder="Select Medicine Type" allowClear>
                        {types.map(type => (
                            <Select.Option key={type.Id} value={type.Id}>{type.Name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="Name" label="Name of Medicine" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                {/* Medicine Route Dropdown */}
                <Form.Item name="MedicineRoute" label="Medicine Route" rules={[{ required: true }]}>
                    <Select placeholder="Select Medicine Route" allowClear>
                        {medicineRoutes.map(route => (
                            <Select.Option key={route.id} value={route.id}>{route.routeName}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.List name="Composition">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'MedicineName']}
                                        rules={[{ required: true, message: 'Enter medicine name' }]}
                                    >
                                        <Input placeholder="Medicine Name" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'Strength']}
                                        rules={[{ required: true, message: 'Enter strength' }]}
                                    >
                                        <Input placeholder="Strength" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'UnitID']}
                                        rules={[{ required: true, message: 'Select unit' }]}
                                    >
                                        <Select placeholder="Select Unit">
                                            {units.map(unit => (
                                                <Select.Option key={unit.Id} value={unit.Id}>{unit.Name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Composition
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item name="DosageID" label="Dosage" rules={[{ required: true }]}>
                    <Select placeholder="Select Dosage" allowClear>
                        {dosages.map(dosage => (
                            <Select.Option key={dosage.ID} value={dosage.ID}>
                                {`${dosage.DosagePattern} (${dosage.MedicineTimeName})`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="FrequencyID" label="Frequency" rules={[{ required: true }]}>
                    <Select placeholder="Select Frequency" allowClear>
                        {frequencies.map(freq => (
                            <Select.Option key={freq.ID} value={freq.ID}>{freq.Name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="NumberOfDays" label="Number of Days" rules={[{ required: true }]}>
                    <Input type="number" min={1} placeholder="Enter number of days" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {editingMedicine ? 'Update Medicine' : 'Add Medicine'}
                    </Button>
                </Form.Item>
            </Form>

            <Table columns={columns} dataSource={medicines} rowKey="ID" loading={loading} pagination={false} />

            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalMedicines}
                onChange={handlePageChange}
                style={{ marginTop: '20px', textAlign: 'center' }}
            />
        </div>
    );
};

export default MedicineForm;
